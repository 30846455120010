import Vue from 'vue'
import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'
import ScrollAnimCtrl from './directives/ScrollAnimCtrl'
import AsyncScroll from './directives/AsyncScroll'
import ScrollLazyLoad from './directives/ScrollLazyLoad'
import '@/common/rem'
import './composition-api'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

import '@/styles/element-variables.scss'
import '@/styles/index.less'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import 'animate.css'

Vue.config.productionTip = false
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.use(ElementUI)
Vue.use(preview)

Vue.directive('anim-ctrl', ScrollAnimCtrl)
Vue.directive('scroll-ctrl', AsyncScroll)
Vue.directive('scroll-lazy', ScrollLazyLoad)

new Vue({
  name: 'ZhikePC',
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
