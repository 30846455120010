import router from '@/router'
import _ from 'lodash'
import { reactive, nextTick } from '@vue/composition-api'

interface navabarState {
  mode: string
  path: string
  visible: boolean
  showcontain: boolean
  collapse: boolean
  currentCllopse: string
  active: boolean
  isIOS: boolean
}

const state = reactive<navabarState>({
  mode: 'dark',
  path: '/',
  visible: false,
  showcontain: true,
  collapse: false,
  currentCllopse: '',
  active: false,
  isIOS: false
})

export function useNavbar() {
  // 路由点击
  const handleClick = (path: string) => {
    if (path == '/') {
      nextTick(() => {
        state.mode =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop > 0
            ? 'light'
            : 'dark'
      })
    }
    router.push({
      path
    })
    state.active = false
    state.visible = false
    state.collapse = false
    state.currentCllopse = ''
  }

  // 点击扩展-mobile
  const handleCollapse = (name: string) => {
    if (state.collapse) {
      if (state.currentCllopse === name) {
        state.collapse = false
        state.currentCllopse = ''
      } else {
        state.currentCllopse = name
      }
    } else {
      state.collapse = true
      state.currentCllopse = name
    }
  }

  // 打开-mobile
  const handleOpen = () => {
    if (!state.active) {
      state.mode = 'light'
    } else {
      onChangeMode()
    }
    state.active = !state.active
    state.visible = !state.visible
  }

  // 导航栏模式 dark | light
  const onChangeMode = () => {
    if (state.path !== '/') {
      nextTick(() => {
        state.mode = 'light'
      })
      return
    }
    nextTick(() => {
      state.mode =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop > 0
          ? 'light'
          : 'dark'
    })
  }

  // 滚动事件监听
  const onScroll = () => {
    if (state.visible) return
    onChangeMode()
  }

  // 屏幕尺寸监听
  const onResize = () => {
    _.throttle(handleResize, 500)()
  }

  // 处理尺寸响应
  const handleResize = () => {
    const width = window.innerWidth
    if (width > 1200) {
      state.showcontain = true
    } else {
      state.showcontain = false
      state.visible = false
    }
    onChangeMode()
  }

  // 初始化判断导航栏模式：dark | light; web | mobile; 安卓机型
  const onShow = () => {
    const u = navigator.userAgent
    const isioS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // 1200 临界区分 web导航和mobile导航
    window.addEventListener('resize', onResize, false)
    const width = window.innerWidth
    if (width > 1200) {
      state.showcontain = true
    } else {
      state.showcontain = false
      state.isIOS = isioS
    }
  }

  return {
    state,
    onShow,
    onResize,
    onScroll,
    handleClick,
    handleOpen,
    handleCollapse
  }
}
