






import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'AppMain',
  props: {
    padding: {
      type: Boolean
    }
  }
})
