/**
 * 动态路由权限接入，请认真阅读文档进行接入
 * 文档地址： http://ued.zuoyebang.cc/documents/zk-fe-docs/#/admin/index?id=%e6%9d%83%e9%99%90%e7%ae%a1%e7%90%86
 */

import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return (originalPush.call(this, location) as any).catch(() => {})
}

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

const router = new Router({
  mode: 'history', // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/',
          component: () => import('@/views/home/index.vue'),
          name: 'Home'
        },
        {
          path: 'introduct',
          component: () => import('@/views/introduct/index.vue'),
          name: 'Introduct'
        },
        {
          path: 'zhzy',
          component: () => import('@/views/goodHomework/index.vue'),
          name: 'Zhzy'
        },
        {
          path: 'zhkt',
          component: () => import('@/views/wisdomClassroom/index.vue'),
          name: 'Zhkt'
        },
        {
          path: 'zhty',
          component: () => import('@/views/smart-sports/index.vue'),
          name: 'zhty'
        },
        {
          path: 'zhty/:type/detail/:id',
          component: () => import('@/views/smart-sports-details/index.vue'),
          name: 'zhtyDetail'
        },
        {
          path: 'khfw',
          component: () => import('@/views/afterClassService/index.vue'),
          name: 'Khfw'
        },
        {
          path: 'news',
          component: () => import('@/views/news/index.vue'),
          name: 'News'
        },
        {
          path: '*',
          component: () => import('@/views/error/index.vue'),
          name: '404'
        }
      ]
    }
  ]
})

export default router
