




import {
  computed,
  defineComponent,
  watch,
  onMounted
} from '@vue/composition-api'
import { useResize } from '@/hooks/config/useResize'
import { useNavbar } from '../Navbar/hook/useNavbar'

export default defineComponent({
  name: 'Navbar',
  components: {
    DeskNavbar: () => import('../Navbar/page/desk.vue'),
    MobileNavbar: () => import('../Navbar/page/mobile.vue')
  },
  emits: ['change-padding'],
  setup(props, { emit, root }) {
    const { state, onShow, onScroll } = useNavbar()

    // 全局判断屏幕尺寸及响应式布局
    useResize()

    onMounted(() => {
      onShow()
    })

    // 路由监听
    watch(
      () => root.$route.path,
      (nval) => {
        if (nval) {
          state.path = nval
          if (state.path == '/') {
            state.mode = 'dark'
            emit('change-padding', false)
            window.addEventListener('scroll', onScroll, false)
          } else {
            state.mode = 'light'
            emit('change-padding', true)
            window.removeEventListener('scroll', onScroll, false)
          }
        }
      },
      { deep: true, immediate: true }
    )

    const navbarType = computed(() =>
      state.showcontain ? 'DeskNavbar' : 'MobileNavbar'
    )

    return {
      navbarType
    }
  }
})
