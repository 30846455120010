








import {
  computed,
  defineComponent,
  ref,
  nextTick,
  onMounted
} from '@vue/composition-api'
import { Navbar, AppMain } from './components'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    DeskFooter: () => import('./components/FooterBar/desk.vue'),
    MobileFooter: () => import('./components/FooterBar/mobile.vue')
  },
  setup() {
    const padding = ref<boolean>(true)
    const allAready = ref<boolean>(false)

    const changePadding = (state: boolean) => (padding.value = state)

    const footerType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskFooter' : 'MobileFooter'
    )

    onMounted(() => nextTick(() => (allAready.value = true)))

    return {
      padding,
      allAready,
      changePadding,
      footerType
    }
  }
})
