import { AppModule, DeviceType } from '@/store/modules/app'
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted
} from '@vue/composition-api'

export function useResize() {
  const device = computed(() => AppModule.device)
  const WIDTH = 800

  const isMobile = () => {
    return window.innerWidth <= WIDTH
  }

  /**计算width 自动关闭sidebar */
  const resizeHandler = () => {
    if (!document.hidden) {
      AppModule.ToggleDevice(
        isMobile() ? DeviceType.Mobile : DeviceType.Desktop
      )
    }
  }

  onBeforeMount(() => {
    window.addEventListener('resize', resizeHandler)
  })

  onMounted(() => {
    if (isMobile()) {
      AppModule.ToggleDevice(DeviceType.Mobile)
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', resizeHandler)
  })

  return {
    device,
    DeviceType
  }
}
